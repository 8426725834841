<template>
  <div>
    <bb-table
      ref="bbTable"
      class="rounded-md"
      :headers="headers"
      :data="variations"
      :is-loading="false"
      :scroll-y="true"
      :sticky-header="true"
      :apply-initial-sort="false"
      :open-pagination-below="false"
      max-table-height="470px"
    >
      <template #rows="{ tableData }">
        <ad-variations-rows :data="tableData" />
      </template>
    </bb-table>
  </div>
</template>

<script>
import BbTable from '@/components/table/BbTable.vue'
import AdVariationsRows from '@/views/site/search/competitor_monitoring/all_competitors/table/ad_variations/AdVariationsRows.vue'

export default {
  name: 'AdVariationsTable',
  components: { BbTable, AdVariationsRows },
  props: {
    variations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'competitorName',
          label: 'Ad variations',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'keywords',
          label: 'Keywords',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'device',
          label: 'Device',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'date-and-time',
          label: 'Date & Time',
          sortable: false,
          sorting: null,
          position: 'left',
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss"></style>
