<template>
  <div class="responsive-section min-h-screen">
    <div class="flex items-center justify-between px-6">
      <div class="flex items-center gap-2 p-6">
        <h4 class="h4 font-medium text-bb-text-default">All Competitors</h4>
        <p class="text-sm text-bb-neutral-gray font-bold bg-neutral-50 py-0.5 px-3 rounded-full">
          {{ allCompetitors.length }}
        </p>
      </div>
      <div>
        <text-input
          v-model="input"
          input-name="search"
          input-id="search"
          input-type="text"
          placeholder="Search..."
          padding-left="pl-10"
          class="w-300"
        >
          <template #prefix>
            <div class="absolute z-10 h-10 p-2 text-bb-disabled-gray">
              <ic-search />
            </div>
          </template>
          <template #suffix>
            <div
              v-if="input"
              class="absolute z-10 right-0 top-0 h-10 py-2 px-1 text-bb-disabled-gray cursor-pointer"
              @click="input = ''"
            >
              <ic-cross class="p-1" />
            </div>
          </template>
        </text-input>
      </div>
    </div>

    <div>
      <all-competitors-table :all-competitors="filteredCompetitors" />
    </div>
  </div>
</template>

<script>
import AllCompetitorsTable from '@/views/site/search/competitor_monitoring/all_competitors/table/competitors/AllCompetitorsTable.vue'

import { mapState } from 'vuex'
import IcSearch from 'vue-material-design-icons/Magnify.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'
import IcCross from '@/components/icon/ic-cross'

import Fuse from 'fuse.js'
import _ from 'lodash'

export default {
  name: 'AllCompetitors',
  components: { TextInput, IcSearch, AllCompetitorsTable, IcCross },
  data() {
    return {
      input: '',
      query: '',
    }
  },
  computed: {
    ...mapState('competitorMonitoring', ['allCompetitors']),
    filteredCompetitors() {
      if (!this.query) return this.allCompetitors

      const rules = {
        shouldSort: true,
        isCaseSensitive: false,
        keys: ['competitorName'],
      }

      const fuse = new Fuse(this.allCompetitors, rules)
      return fuse.search(this.query).map(result => result.item)
    },
  },
  watch: {
    input(...args) {
      this.debounceQuery(...args)
    },
  },
  mounted() {
    this.debounceQuery = _.debounce(input => {
      this.query = input
    }, 300)
  },
  beforeUnmount() {
    this.debounceQuery.cancel()
  },
}
</script>

<style scoped lang="scss"></style>
