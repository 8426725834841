<template>
  <tbody class="divide-y divide-bb-neutral-50 overflow-y-scroll">
    <!--Empty Table-->
    <tr v-if="tableRows.length === 0">
      <td colspan="13">
        <div class="mx-auto my-20 flex flex-col items-center justify-center">
          <img
            class="w-24 h-auth"
            src="/content/images/illustrations/binoculars.png"
            alt="dashboard"
          />
          <p class="my-2 w-100 text-center">No ad variations found for the selected competitor.</p>
        </div>
      </td>
    </tr>
    <tr
      v-for="(item, index) in tableRows"
      v-else
      :key="index"
      class="h-35 whitespace-no-wrap font-normal w-full text-sm"
      :class="hoveredRow === index ? 'bg-bb-background-purple-0' : ''"
      @mouseenter="hoveredRow = index"
      @mouseleave="hoveredRow = null"
    >
      <td
        class="whitespace-no-wrap sticky left-0"
        :class="hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white'"
      >
        <div
          class="space-y-2 flex flex-col justify-center items-start h-35 px-4"
          :class="item?.isNew ?? false ? 'gradient-border' : ''"
        >
          <div class="flex items-start gap-2">
            <img
              :src="`https://www.google.com/s2/favicons?domain=${item.url}`"
              class="pt-1"
            />
            <div class="text-xs">
              <p class="font-medium">{{ item.competitorName }}</p>
              <a
                :href="item.url"
                target="_blank"
                @click.stop
              >
                <p class="text-xs font-normal text-bb-neutral-gray hover:text-bb-brand-purple">{{ item.url }}</p>
              </a>
            </div>
          </div>
          <div class="w-110">
            <a
              :href="item.landingAdUrl"
              target="_blank"
              @click.stop
            >
              <p class="text-sm text-bb-brand-purple font-medium">{{ item.title }}</p>
            </a>
            <p
              class="text-xs whitespace-pre-wrap"
              style="line-height: 14px"
            >
              {{ item.description }}
            </p>
          </div>
        </div>
      </td>
      <td class="px-4 whitespace-pre-wrap">
        <p>{{ item.keywords.join(', ') }}</p>
      </td>
      <td class="w-15 text-center px-4 space-y-1">
        <div
          v-for="device in item.devices"
          :key="device"
          class=""
        >
          <div class="flex items-center gap-1">
            <component
              :is="`ic-${device}`"
              :size="20"
              class="text-bb-disabled-gray"
            />
            <p class="font-medium capitalize">{{ device }}</p>
          </div>
        </div>
      </td>
      <td class="w-30 text-right px-4 text-xs space-y-2">
        <div>
          <p>Last Seen</p>
          <p class="pNum">{{ item.dateLastSeen }} {{ item.timeLastSeen }}</p>
        </div>
        <div>
          <p>First Seen</p>
          <p class="pNum">{{ item.dateFirstSeen }} {{ item.timeFirstSeen }}</p>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import IcChecked from 'vue-material-design-icons/CheckCircleOutline'
import IcFlag from '@/components/icon/brightbid/ic-flag.vue'
import IcMobile from '@/components/icon/brightbid/IcMobile.vue'
import IcTablet from '@/components/icon/brightbid/IcTablet.vue'
import IcDesktop from '@/components/icon/brightbid/IcDesktop.vue'
import ChipsCollection from '@/components/shared/ChipsCollection.vue'
import dayjs from 'dayjs'
import IcViews from '@/components/icon/brightbid/IcViews.vue'
export default {
  name: 'AdVariationsRows',
  components: { IcViews, IcMobile, IcTablet, IcDesktop, ChipsCollection, IcChecked, IcFlag },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hoveredRow: null,
    }
  },
  computed: {
    tableRows() {
      const rows = structuredClone(this.data)

      return rows.map(item => {
        item.dateFirstSeen = dayjs(item.firstSeenAt).format('MM/DD/YYYY')
        item.timeFirstSeen = dayjs(item.firstSeenAt).format('HH:mm')
        item.dateLastSeen = dayjs(item.lastSeenAt).format('MM/DD/YYYY')
        item.timeLastSeen = dayjs(item.lastSeenAt).format('HH:mm')
        return item
      })
    },
  },
}
</script>
<style scoped lang="scss">
.gradient-border {
  border-left: 2px solid;
  border-image-source: linear-gradient(180deg, #f23f44 0%, #ff9900 100%);
  border-image-slice: 1;
}
</style>
